.wizard {
    font-family: 'AkagiProW00-Fat', Helvetica, sans-serif !important;
    // margin-top: 1rem;
    margin: 1rem auto;
    position: relative;
    margin-bottom: 50px;
    @media (min-width: 576px) {
        left: 29px;
        width: 80%;
    }
}
.wizard__steps {
    padding: 0.6rem;
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    @media (min-width: 768px) {
        transform: translateX(30px);
        width: 80%;
        padding: 0.6rem;
    }
    margin: auto;
    .wizard__step_wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        // transform: translateX(50%);
        // &:nth-last-child(1) {
        //     width: auto;
        // }
        //left: 60px;
        &:nth-last-child(1)::after {
            display: none;
        }
        // After line of steps
        &::after {
            display: none;
            z-index: 9;
            position: absolute;
            content: '';
            height: 3px;
            top: calc(30px - 1px);
            left: 60px;
            background-color: $baseBlackLight-color;
            width: calc(50% - 29px);
            @media (min-width: 576px) {
                display: block;
            }
        }
        &.active::after {
            background-color: $active;
        }
        &.passed::after {
            background-color: $passed;
        }
        // Before line of steps | except first
        &:not(.wizard__step_wrapper-0)::before {
            display: none;
            z-index: 9;
            position: absolute;
            content: '';
            height: 3px;
            top: calc(30px - 1px);
            right: 100%;
            background-color: $baseBlackLight-color;
            width: calc(50% - 29px);
            @media (min-width: 576px) {
                display: block;
            }
        }
        &.active::before {
            background-color: $active;
        }
        &.passed::before {
            background-color: $passed;
        }
    }
}
.wizard__step-container {
    z-index: 10;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: $primary-color;
    border: 1px solid $baseBlackLight-color;
    color: $baseBlackLight-color;
    &.passed {
        border-color: $passed;
        background-color: $passed;
        color: white;
    }
    &.active {
        border-color: $active;
        background-color: $active;
        color: white;
    }
}

.wizard__step-label {
    color: $baseBlackLight-color;
    &.active {
        color: $active;
    }
    &.passed {
        color: $passed;
    }
}
