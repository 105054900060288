/**
Base colors
*/
$base-color: #009793;
$baseDark-color: #004A47;
$baseLight-color: #22B9D8;
$baseGray-color: #F2F2F4;
$baseBlack-color: #111111;
$baseBlackLight-color: #898989;


$passed: #B2D135;
$active: #56BDEA;
$warning: #ed6c02;
$black-color: #111111;
$primary-color: #009793;
$secondary-color: #009793;
$primary-border: 1px solid rgba(221, 221, 221, 0.8666666667);

$primary-text: white;
$secondary-text: black;