
.privacy {
    font-size: .9rem;
    margin: auto;
    max-width: 700px;
    .privacy-url {
      // color: $color-connectplus;
      color: $primary-color;
    }
    p {
      margin: 1rem 0;
    }
    h1 {
      text-align: center;
      text-decoration: underline;
    }
    h1, h2, h3 {
      font-size: 1rem;
      font-weight: bolder;
    }
    h2 {
      margin-top: 1.4rem;
    }
  }