.summary__logo {
    max-width: 200px;
}

.summary__grid {
    position: relative;
    // margin-bottom: 1rem !important;
}
.summary__grid_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 15rem !important;
    transform: translate(-50%, -50%);
    opacity: .07
}
.tableCell__name {
    // color: white !important;
    font-weight: 400 !important;
}
.tableCell__value {
    font-weight: 800 !important;   
}
.summary__logo {
    height: fit-content;
}
.summary__grid_buttons .MuiPaper-root{
    border: 5px solid $base-color;
}
.summary__buttons button{
    height: fit-content;
    border: 3px solid black!important;
}
// .summary__grid_buttons {
//     background-color: #56bdea38;
// }